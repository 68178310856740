import React from 'react';
import BlackLogo from '../assets/img/BlackLogo.png';
import { Link, Text } from '.';

type Props = {};

export const Footer: (props: Props) => JSX.Element | null = () => {
    return (
        <footer className="footer bg-repeat relative mt-16 bg-footer-texture">
            <div className="container mx-auto px-4 grid grid-cols-1 lg:grid-cols-4 gap-4 text-xs">
                <div className="mb-6 lg:mb-0">
                    <img alt="card" src={BlackLogo} className="max-w-[260px]" />
                </div>
                <div className="mb-6 lg:mb-0">
                    <p className="font-bold">bonusukarte.lv SIA</p>
                    <Text label="bk.footer.registration_number" />
                    <p className="max-w-xs"><Text label="bk.footer.legal_address" /></p>
                </div>
                <div className="mb-6 lg:mb-0">
                    <h3 className="font-bold mb-2"><Text label="bk.footer.legal_information" /></h3>
                    <div><Link dark to="https://business.bonusukarte.lv/terms" external={true} targetBlank={true} label="bk.footer.link.terms" className="font-normal !hover:text-bk-black" /></div>
                    <div><Link dark to="https://business.bonusukarte.lv/delivery-policy" external={true} targetBlank={true} label="bk.footer.delivery_policy" className="font-normal !hover:text-bk-black" /></div>
                    <div><Link dark to="https://bonusukarte.lv/lv/privatuma-politika" external={true} targetBlank={true} label="bk.footer.privacy_policy" className="font-normal !hover:text-bk-black" /></div>
                </div>
                <div className="mb-6 lg:mb-0">
                    <h3 className="font-bold mb-2"><Text label="bk.footer.contact_information" /></h3>
                    <p>
                        <Text label="bk.footer.link.phone" />: <a className="underline" href="tel:+37125421212">+371 2542 1212</a>
                    </p>
                    <p>
                        <Text label="bk.footer.link.email" />: <a className="underline" href="mailto:tava@bonusukarte.lv">tava@bonusukarte.lv</a>
                    </p>
                </div>
                <div className="col-span-full text-center text-xs opacity-60 mt-12 lg:col-span-4">
                    <p><Text className="text-xxs" label="bk.footer.license_info" /></p>
                    <p className="font-bold mt-4">© {(new Date().getFullYear())} bonusukarte.lv SIA</p>
                </div>
            </div>
        </footer>
    );
};