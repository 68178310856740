import moment from 'moment';


export function formatDate(
    dateToFormat: null | undefined | Date,
    withTime = false,
    withMilliseconds = false,
    customFormat: string | undefined = undefined
): string {

    if (dateToFormat === null || !dateToFormat) {
        return '-';
    }

    const date = moment(dateToFormat).local();

    if (customFormat) {
        return date.format(customFormat);
    }

    if (!withTime) {
        return date.format('DD.MM.YYYY');
    }

    if (withMilliseconds) {
        return moment(dateToFormat).local().format('DD.MM.YYYY HH:mm:ss.SSS');
    }

    return date.format('DD.MM.YYYY HH:mm');
}

export function formatNumber(number: null | undefined | string | number, round=false) {

    if (number === undefined || number === null) {
        return '0';
    }

    if (round) {
        number = (Math.round((typeof number === 'string' ? parseFloat(number) : number) * 100) / 100).toFixed(2);
    }

    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatDateForBackend(date: null | moment.Moment) {
    if (date === null) {
        return null;
    }

    return date.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
}

export const getCurrentDateString = (
    backEndFormat = false,
    yearsFromNow = 0
) => {
    const today = new Date();
    const yyyy = today.getFullYear() - yearsFromNow;
    let mm: any = today.getMonth() + 1; //months start at 0
    let dd: any = today.getDate();

    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }

    if (backEndFormat) {
        return `${yyyy}-${mm}-${dd}`;
    }

    return dd + '.' + mm + '.' + yyyy;
};