import React, { useEffect, Component, ErrorInfo } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { LoadingWithSuspense, Error, PrivateRoute } from './components';
import { useDispatch } from 'react-redux';
import { userActions } from './_actions/';
import { useAppSelector} from './_library';
import config from './config';
import { Dashboard } from './screens/Dashboard';
const PhoneVerification = React.lazy(() => import(/* webpackChunkName: "phone_verification" */ './screens/PhoneVerification').then(module => ({default: module.PhoneVerification})));
const SetPin = React.lazy(() => import(/* webpackChunkName: "set_pin" */ './screens/SetPin').then(module => ({default: module.SetPin})));
const SignIn = React.lazy(() => import(/* webpackChunkName: "sign_in" */ './screens/SignIn').then(module => ({default: module.SignIn})));
const CardVerification = React.lazy(() => import(/* webpackChunkName: "card_verification" */ './screens/CardVerification').then(module => ({default: module.CardVerification})));
const PinVerification = React.lazy(() => import(/* webpackChunkName: "pin_verification" */ './screens/PinVerification').then(module => ({default: module.PinVerification})));
const Settings = React.lazy(() => import(/* webpackChunkName: "settings" */ './screens/Settings').then(module => ({default: module.Settings})));
const PageNotFound = React.lazy(() => import(/* webpackChunkName: "page_not_found" */ './screens/PageNotFound').then(module => ({default: module.PageNotFound})));
const Balance = React.lazy(() => import(/* webpackChunkName: "balance_check" */ './screens/Balance').then(module => ({default: module.Balance})));
const BalanceCheck = React.lazy(() => import(/* webpackChunkName: "balance_check" */ './screens/BalanceCheck').then(module => ({default: module.BalanceCheck})));


class ErrorBoundary extends Component {
    constructor(props: {children?: any, [key: string]: any}) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(e: any) {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: ErrorInfo) {
    }

    render() {
        if ((this.props as any).hasError) {
            return <Error mainLabel="bk.placeholder.error.general" buttonLabel="bk.placeholder.error.general.link.home" link="/" />
        }

        return <div className="position-relative">{(this.props as any).children}</div>;
    }
}

type Props = {};

const App: (props: Props) => JSX.Element = () => {

    const dispatch = useDispatch();
    const user = useAppSelector(state => state.user);

    useEffect(() => {
        if (!user.synchronized) {
            (userActions.loadSettings())(dispatch)
        }
    }, []);

    return (
        <ErrorBoundary>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<LoadingWithSuspense Component={SignIn} />} />
                    <Route path="/signin" element={<LoadingWithSuspense Component={SignIn} />} />
                    <Route path="/verify/phone" element={<LoadingWithSuspense Component={PhoneVerification} />} />
                    <Route path="/verify/card" element={<LoadingWithSuspense Component={CardVerification} />} />
                    <Route path="/verify/pin" element={<LoadingWithSuspense Component={PinVerification} />} />
                    <Route path="/set/pin" element={<LoadingWithSuspense Component={SetPin} />} />
                    <Route path="/balance" element={<LoadingWithSuspense Component={Balance} />} />
                    <Route path="/balance/check" element={<LoadingWithSuspense Component={BalanceCheck} />} />
                    <Route path="/home"
                           element={<PrivateRoute Component={Dashboard} minimalRole={config.userRoles['user']} />}
                    />
                    <Route path="/settings"
                           element={<PrivateRoute Component={Settings} minimalRole={config.userRoles['user']} />}
                    />
                    <Route path="/404" element={<LoadingWithSuspense Component={PageNotFound} />} />
                    <Route element={<LoadingWithSuspense Component={PageNotFound} />} />
                </Routes>
            </BrowserRouter>
        </ErrorBoundary>
    );
};

export default App;
