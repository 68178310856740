import { useNavigate } from 'react-router-dom';
import { store} from '../_library';
import config from '../config';
import { LoadingWithSuspense } from './LoadingWithSuspense';

type Props = {
    Component: any,
    minimalRole?: number,
    exactRole?: number,
    [key: string]: any
};

export const PrivateRoute: (props: Props) => JSX.Element | null = ({ Component, minimalRole = 0, exactRole, ...rest }) => {

    const navigate = useNavigate();
    if (!store.getState().user.synchronized) {
        return null;
    }

    const token = localStorage.getItem(config.accessTokenName);
    if (!store.getState().user.loggedIn && !token) {
        navigate('/signin');
        return null;
    } else {
        return <LoadingWithSuspense Component={Component} props={rest} />;
    }
}
