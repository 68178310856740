import React from 'react';
import { ReactComponent as ApiIcon } from '../assets/img/icons/API.svg';
import { ReactComponent as DownloadIcon } from '../assets/img/icons/Download.svg';
import { ReactComponent as PencilIcon } from '../assets/img/icons/Pencil.svg';
import { ReactComponent as ArrowIcon } from '../assets/img/icons/Arrow.svg';
import { ReactComponent as AddUserIcon } from '../assets/img/icons/AddUser.svg';
import { ReactComponent as SearchIcon } from '../assets/img/icons/Search.svg';
import { ReactComponent as CloseIcon } from '../assets/img/icons/Close.svg';
import { ReactComponent as BackArrowIcon } from '../assets/img/icons/ArrowBack.svg';


type Props = {
    type: 'api' | 'download' | 'arrow' | 'pencil' | 'addUser' | 'search' | 'close' | 'backArrow',
    children?: React.ReactNode,
    onClick?: () => void,
    className?: string
}

export const Icon: (props: Props) => JSX.Element =
    ({
         type,
         onClick,
         children,
         className
    }) => {

    let IconSvg;

    switch(type) {
        case 'api':
            IconSvg = ApiIcon;
            break;
        case 'download':
            IconSvg = DownloadIcon;
            break;
        case 'arrow':
            IconSvg = ArrowIcon;
            break;
        case 'pencil':
            IconSvg = PencilIcon;
            break;
        case 'addUser':
            IconSvg = AddUserIcon;
            break;
        case 'search':
            IconSvg = SearchIcon;
            break;
        case 'close':
            IconSvg = CloseIcon;
            break;
        case 'backArrow':
            IconSvg = BackArrowIcon;
            break;
        default:
            IconSvg = 'span';
    }

    if (children) {
        return (
            <div className="d-inline-block position-relative">
                <IconSvg onClick={(onClick ? onClick : () => {})} className={'' + (className || '')}/>
                {children}
            </div>
        );
    }

    return <IconSvg onClick={(onClick ? onClick : () => {})} className={'' + (className || '')} />;
};
