import localeDataEn from '../translations/en.json';
import localeDataLv from '../translations/lv.json';
import { i18nConstants } from '../_constants';
import config from '../config';

const initialState = {
    lang: 'lv',
    messages: localeDataLv,
    synchronized: false
};

const supportedLanguages = ['LV', 'EN'];

export function i18n(state = Object.assign({}, initialState), action: any) {
    switch (action.type) {
        case i18nConstants.CHANGE_LANGUAGE:
            if (action.data.lang === null || action.data.lang === undefined || !supportedLanguages.includes(action.data.lang.toString().toUpperCase())) {
                state.lang = 'lv';
            } else {
                state.lang = action.data.lang;
            }
            localStorage.setItem(config.language, state.lang);

            switch (action.data.lang) {
                case 'lv':
                    state.messages = localeDataLv;
                    break;
                case 'en':
                    state.messages = localeDataEn;
                    break;
                default:
                    break;
            }

            return Object.assign({}, state);
        default:
            return state;
    }
}
