import axios from 'axios';
import config from '../config';

export const api = axios.create({
    baseURL: config.baseUrl
});

api.interceptors.request.use(function (axiosConfig) {
    if (localStorage.getItem(config.accessTokenName)) {
        axiosConfig.headers.Authorization = 'Bearer ' + localStorage.getItem(config.accessTokenName);
    }

    if (localStorage.getItem(config.language)) {
        axiosConfig.headers['Accept-Language'] = localStorage.getItem(config.language);
    } else {
        axiosConfig.headers['Accept-Language'] = 'en';
    }

    return axiosConfig;
});

api.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response && error.response.status === 403 && !error.config.url.includes('/users/profile')) {
            localStorage.removeItem(config.accessTokenName);
            window.location.pathname = '/signin';
        }

        return Promise.reject(error);
    }
);

export type CompanyUserClient = {
    id: number,
    name: string,
    role: string,
    companyId: number,
    kycStatus: string
}

export type LoginResponse = {
    id: number,
    email: string,
    status: string,
    language: string,
    currentClient: number,
    role?: string,
    currentKycStatus?: string,
    clients: CompanyUserClient[]
}
