import React, {Fragment, useState} from "react";
import {Popover, Transition} from "@headlessui/react";
import {Button} from "./Button";


type Props = {
    btnLabel: string,
    disabled?: boolean,
    btnBadge?: number, //number to display as badge on button
    content: any
};

export const Dropdown: (props: Props) => JSX.Element =
    ({
         btnLabel,
         disabled,
         btnBadge,
         content
    }) => {

    return (
        <Popover className="relative">
            {({ open }) => (
                <>
                    <Popover.Button as="div" className="">
                        <Button
                            badge={btnBadge}
                            noShadow={true}
                            disabled={disabled}
                            color="transparent"
                            label={btnLabel}
                            onClick={() => {}}
                        />
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute right-0 z-10 mt-3 w-screen max-w-sm -translate-x-1 transform px-4 sm:px-0 lg:max-w-2xl">
                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                <div className="relative bg-white p-6">
                                    {content}
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
};
